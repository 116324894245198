
.footer{
  position: absolute;
  bottom: 0;
  width: 98vw;
  left: 0;
}

  .footer-nav ul{
   color: #727272;
   font-family: "Neue Regrade";
   display: flex;
   align-items: center;
   justify-content: end;
   list-style: none;
   text-decoration: none;
   gap: 30px;
  
  }
  .footerbase{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    height: 100%;
  }

  .left{
    margin-bottom: 20px;
  }

/* mobile responsive */
@media (max-width: 767px) {
  .footer{
    margin-top: 50px;
    position: relative;
  }
  .footer-nav{
    display: none;
  }

  
}

.footer-text{
  font-family: "Neue Regrade";
  color: #727272;
  font-size: 16px;
  text-align: center;
}

.na{
  text-decoration: none;
  color: #727272;
}