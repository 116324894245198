.project-card {
    position: relative;
    padding: 16px;
}

.image-container {
    position: relative;
}

.project-img {
    display: block;
    width: 100%;
    height: auto;
    
}

.watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: rgba(255, 255, 255, 0.7);
    /* background-color: rgba(0, 0, 0, 0.5); */
    padding: 10px;
    border-radius: 5px;
    pointer-events: none; /* Ensure the watermark does not interfere with any interactions */
}

.flex-align {
    display: flex;
    align-items: center;
}

.space-between {
    justify-content: space-between;
}

.project-card-name {
    margin: 0;
}

.project-card-year, .project-card-place, .project-link {
    margin: 0;
}

.project-card-btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.project-card-arrow {
    margin-left: 8px;
    width: 16px;
    height: 16px;
}
