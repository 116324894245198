.container-back {
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .back-btn {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 8px;
    font-size: 16px;
  }
  
  .back-icon {
    margin-right: 8px; 
  }
  
  .back-text {
    color: #313131;
    margin: 0;
  }