.gaurang img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 537px;
    margin-bottom: 50px;
}

.project-details-container {
    padding: 50px 0;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .project-name {
    grid-column: span 1;
    font-size: 32px;
    font-weight: bold;
  }
  
  .dummy-text {
    grid-column: span 1;
    font-family: "Neue Regrade";
    align-self: center;
    text-align: justify;
    color: #727272;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .project-info {
    grid-column: span 2;
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    gap: 0px;
    margin-top: 20px;
    font-size: 20px;
    text-align: end;
    font-family: "Neue Regrade";
  }
  .area{
    display: grid;
    grid-template-columns: repeat(2, 2fr);
  }
  
  .project-info div {
    color: #727272;
  }
  
  .technical {
    grid-column: span 1;
    font-size: 32px;
    font-weight: bold;
  }
  
  .details {
    /* grid-column: span 2; */
    font-family: "Neue Regrade";
    
    /* padding: 20px; */
    /* background-color: #f8f9fa; */
  }
  
  .details h1 {
    font-size: 31px;
    font-weight: 600;
    margin-bottom: 10px;
   
  }
  
  .details ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
    
   
  }
  
  .details li {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.6;
    color: #727272;
  }
  

.justify-between{
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.flex{
  margin-bottom: 50px;
}

.heading h1{
 
  font-family: "Neue Regrade";
  margin-bottom: 50px;  
  margin-top: 30px;
}
/* .container-hover:hover{
  transform: scale(1.7); 
  transition: ease-in-out 0.3s;
    cursor: pointer; 
} */

.bhoomi{
 margin-bottom: 1.0625em;
 font-family:"Neue Regrade ";
}

.container-hover {
  overflow: hidden; 
  position: relative; 
  transition: transform 0.3s ease-in-out
}

.container-hover img {
  width: 100%;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.container-hover:hover {
  transform: scale(1.9); 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); 
  cursor: pointer;
  z-index: 12;
}

.container-hover:hover img {
  transform: scale(1.1); 
}
.new-content{
  width: 100%;
}



