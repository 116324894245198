/* Add this CSS in your styles.css or styled-components */
.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 98vw;
    height: 96vh;
    font-family: 'Arial', sans-serif;
  }
  
  .coming-soon-container svg {
    color: #3498db; /* Change the color as desired */
  }
  
  h1 {
    margin-top: 20px;
    font-size: 2em;
    color: #333;
  }
  
  p {
    text-align: center;
    color: #555;
  }
  
  .countdown {
    margin-top: 20px;
    font-size: 1.2em;
    text-align: center;
    color: #777;
  }
  
  .countdown p:first-child {
    font-weight: bold;
    color: #333;
  }
  
  /* Optional: Add media queries for responsive design */
  @media (max-width: 600px) {
    h1 {
      font-size: 1.5em;
    }
  
    p {
      font-size: 0.9em;
    }
  
    .countdown {
      font-size: 1em;
    }
  }
  