.revvs{
    margin-top: 60px;
    margin-bottom: 50px;
}

.foo{
    font-family: "Neue Regrade";
    
}


.container {
   
    margin-right: 45px;
  }
  
  .revvs p {
    color: #333;
    margin-bottom: 20px;
  }
  
  .carousel .slide {
    background: none;
  }
  
  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    display: none;
  }
  
  .carousel-buttons {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    gap: 10px;
  }
  
  .carousel-buttons .prev-button, .carousel-buttons .next-button {
    background-color: transparent;
    border: none;
    font-size: 24px;  
    cursor: pointer;
    margin: 0 10px;
    color: #333;
    transition: color 0.3s;
  }
  
  .carousel-buttons .prev-button:hover, .carousel-buttons .next-button:hover {
    color: #000;
    
  }

  .container2{
    max-width: 1600px;
    width: 100%;

  }

  .container2 h1{
    
    font-family: "Neue Regrade";
  }

  .left p{
    font-family: "Neue Regrade";

    text-align: left;
    
  }

  .container-rev{
   margin-bottom: 50px; 
  }



  