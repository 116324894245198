@import url('../fonts/neue.css');

.header {
    padding: 0 1.6vw 1.2vw;
    border-bottom: 0.1vw solid var(--bc);
    margin-bottom: var(--header-b-m);
}

.head-logo {
    width: 8.15vw;
}

.head-menu {
    padding:  0.53vw 1.2vw; 
    background-color: var(--bgc2);
    border-radius: 2.61vw;
}

.menu-icon {
    width: 1.8vw;
    height: 1.8vw;
    margin-left: 0.7vw;
}

.menu-text {
    color: #000000;
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 600;
    margin-bottom: -0.25vw;
}

/* preloader */
.preloader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bgc);
    z-index: 9999;
}
.preloader-gif{
    width: 10vw;
}

/* modes */
.ws-colorL{
    background-color: #FFECC7;
}
.ws-colorD{
    background-color: #474747;
}
.ws-mode{
    border-radius: 2.61vw;
    padding: 0.5vw;
    width: 5vw;
    height: 2.8vw;
    margin-right: 0.8vw;
    position: relative;
}
.mode-icon{
    width: 1.9vw;
    height: 1.9vw;
    position: absolute;
    left: 0.5vw;
    transition: 0.5s;
}
.mob-mode-icon{
    display: none;
}
/* .mode-iconL{
    right: auto;
    left: 0.5vw;
}
.mode-iconD{
    right: 0.5vw;
    left: auto;
} */

/* mobile responsive */
@media (max-width: 767px) {
    .head-logo{
        width: 22.3vw;
    }
    .head-menu{
        border-radius: 4.54vw;
        padding: 2.25vw 4.5vw;
    }
    .ws-mode{
        display: none!important;
    }
    .mob-mode-icon{
        display: block;
        margin-right: 1vw;
        width: 7.61vw;
        height: 7.61vw;
    }
    .menu-text{
        font-size: 2.717vw;
    }
    .menu-icon{
        width: 3.61vw;
        height: 3.61vw;
        margin-left: 1.655vw;
    }
    .preloader-gif{
        width: 25vw;
    }
  }