/* menu */
.menu {
    position: fixed;
    top: 100vh;
    left: 0;
    z-index: 99;
    background-color: var(--bg-menu);
    width: 93.4vw;
    min-height: 100vh;
    padding: 1.2vw 3.3vw;
    transition: 0.5s;
}
.menu-box {
    padding: 0 1.6vw 1.2vw;
    border-bottom: 0.1vw solid var(--wc);
}
.menu-link{
    margin-top: 6.1vw; /* 94px */
    margin-left: 7.15vw; /* 110px */
}
.menu-btn{
    background-color: var(--bg-menu);
    border: none;
    color: var(--wc2);
    text-align: center;
    font-family: var(--font);
    font-size: 3.2vw; /* 49px */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.95vw; /* 30px */
}
.menu-btn:hover{
    color: var(--p-color);
}
.menu-social-p{
    color: var(--wc2);
}
.menu-social-links .connect-div{
    margin: 0 1vw;
}
.menu-social-links{
    position: absolute;
    bottom: 4vw;
    left: 50%;
    transform: translate(-50%);
}

/* mobile responsive */
@media (max-width: 767px) {
    .menu {
        padding: 2.2vw 3.3vw;
    }
    .menu-box {
        padding: 0 1.6vw 2.2vw;
    }
    .menu-btn{
        font-size: 6.2vw;
        margin-bottom: 5vw;
    }
    .menu-social-links{
        bottom: 22vw;
    }
    .menu-social-links .connect-div {
        margin: 0 2vw;
    }
  }