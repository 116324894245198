.home-left {
    position: absolute;
    width: 42.5vw;
    top: 9.34vw;
    right: 18.5vw;
    transition: 0.5s;
}

.link-arrow {
    width: 1.8vw;
    height: 1.8vw;
    margin-right: 0.3vw;
    transition: var(--t-time);
}

.social-links {
    position: absolute;
    top: 4vw;
    right: 0;
    transform: translate(120%);
    transition: 0.5s;
}

.social-link {
    margin-bottom: 0.3vw;
}

.social-link:hover>.link-p {
    font-size: var(--fs-hover);
}

.social-link:hover>.link-arrow {
    transform: rotate(30deg) scale(1.3);
}

.link-p {
    color: var(--bc);
    font-family: var(--font);
    font-size: var(--fs);
    /* 16px */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: var(--t-time);
}

.counter-section {
    margin-top: 2.8vw;
    /* 47px */
    transition: 0.5s;
}

.counter-num {
    color: var(--p-tc);
    font-family: var(--font);
    font-size: var(--fsc);
    /* 31px */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.counter-text {
    color: var(--s-tc);
    font-family: var(--font);
    font-size: var(--fs);
    /* 16px */
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.counter-box {
    margin-right: 3.28vw;
    /* 50px */
}


/* page number */
.p-num {
    position: fixed;
    bottom: 2.7vw;
    /* 42px */
    right: 18.2vw;
    color: transparent;
    -webkit-text-stroke: var(--stroke);
    /* For Chrome, Safari, and newer versions of Edge */
    font-family: var(--font);
    font-size: var(--fsp);
    /* 100px */
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


/* headset */
.headset {
    position: absolute;
    left: 10.95vw;
    /* 168px */
    top: 12.5vw;
    /* 192px */
    max-width: 36.65vw;
    /* 563px */
    transition: 0.5s;
}

.footer_set {
    max-width: 100%;
    transition: all .5s;
    position: absolute;
    bottom: 0;
    right: 50%;
}

.mini-head {
    color: var(--p-tc);
    font-family: var(--font);
    font-size: var(--fsm);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left;
}

.main-head {
    color: var(--p-tc);
    font-family: var(--font);
    font-size: var(--fs-head);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0.7vw 0;
}

.head-para {
    color: var(--s-tc);
    text-align: justify;
    font-family: var(--font);
    font-size: var(--fs-para);
    font-style: normal;
    font-weight: 500;
    line-height: 155.556%;
    /* 21.778px */
}

.invert-p {
    max-width: 26vw;
}

.action-btn {
    background-color: var(--p-color);
    padding: 0.95vw 2.9vw;
    /* 15 45 px */
    margin-top: var(--top-m);
    border: none;
    transition: var(--t-time);
}

.action-btn p {
    color: var(--wc);
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: -0.25vw;
    transition: var(--t-time);
}

.action-btn:hover {
    background-color: var(--wc2);
}

.action-btn:hover>p {
    color: var(--bc);
}

/* projects */
.projects-section {
    right: -100vw;
    transition: 0.5s;
    margin-bottom: 50px;
}

.project-carousle {
    width: 100%;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
    transition: 0.5s;
}

.page-head {
    color: var(--bc);
    font-family: var(--font);
    font-size: var(--fsp-head);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: var(--subhead-bm);
    transition: 0.5s;
}

.project-card {
    min-width: 39.05vw;
    max-width: 39.05vw;
    margin-right: 1.31vw;
}

.project-img {
    width: 100%;
    /* height: 19.525vw; */
    background-color: #d0d0d0;
    margin-bottom: 20px;
}

.project-card-name {
    color: var(--mh-tc);
    font-family: var(--font);
    font-size: var(--fssm);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.3vw;
}

.project-card-place {
    color: var(--place-tc);
    text-align: justify;
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 500;
    line-height: 155.556%;
    /* 24.889px */
}

.project-card-year {
    color: var(--s-tc);
    text-align: justify;
    font-family: var(--font);
    font-size: var(--fsm);
    font-style: normal;
    font-weight: 600;
    line-height: 155.556%;
    /* 31.111px */
}

.project-link {
    color: var(--p-color);
    font-family: var(--font);
    font-size: var(--fsm);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.imgcard {
    margin-bottom: 1.7vw;
    transition: 0.5s;
}

.imgcard-left {
    position: relative;
    left: -100vw;
}

.imgcard-right {
    position: relative;
    right: -100vw;
}

.projectcard-left {
    position: relative;
    left: -100vw;
}

.projectcard-right {
    position: relative;
    right: -100vw;
}

.project-card-btn {
    background-color: transparent;
    padding: 0;
    margin-top: 1.05vw;
}

.project-card-arrow {
    display: flex;
    width: 2vw;
    height: 2vw;
    justify-content: center;
    align-items: center;
}

/* scroll */
.page-direction {
    position: fixed;
    left: 50%;
    bottom: 3.31vw;
    transform: translate(-50%);
    transition: 0.5s;
}

.page-direction-p {
    color: var(--place-tc);
    text-align: justify;
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 500;
    line-height: 155.556%;
    /* 24.889px */
}

.page-direction-icon {
    width: 30px;
    height: 30px;
    margin-left: 0.7vw;
}

/* about */
.about-section {
    max-width: var(--container);
    margin: 0 auto;
}

.amit {
    width: 26.05vw;
    /* 400px */
}

.about-left {
    left: -100vw;
    width: 27.75vw;
    /* 426px */
    transition: 0.5s;
}

.about-right {
    right: -100vw;
    transition: 0.5s;
}

.about-get {
    margin-top: 3.2vw;
}

.about-yhead {
    color: var(--p-color);
    font-family: var(--font);
    font-size: var(--fsm);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-bottom: var(--mrb-10);
}

.contact-to {
    color: var(--s-tc);
    text-align: justify;
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 500;
    line-height: 155.556%;
    /* 24.889px */
}

.sub-head {
    max-width: 27.75vw;
    /* 426px */
}

/* contact */
.contact-top {
    left: -100vw;
    transition: 0.5s;
}

.contact-second {
    right: -100vw;
    transition: 0.5s;
}

.connection-links {
    margin-top: 2.3vw;
    /* 35px */
}

.connect-link {
    max-width: 13.8vw;
    /* 212px */
    background-color: transparent;
    margin-right: 8.15vw;
    /* 125px */
    padding: 0;
}

.connect-div {
    margin-bottom: var(--mrb-10);
}

.connect-head {
    color: var(--c-tc);
    text-align: justify;
    font-family: "Neue Regrade";
    font-size: var(--fscon);
    font-style: normal;
    font-weight: 700;
    line-height: 155.556%;
    /* 24.889px */
}

.contact-para {
    font-size: var(--fs);
}

.clients-box {
    width: 70vw;
}

.contact-form {
    display: flex;
    flex-direction: column;
}

.inputs {
    margin-bottom: 1vw;
    background-color: transparent;
    padding: 1.2vw;
    border-radius: 0.3vw;
    border: 1px solid #DADADA;
    color: var(--s-tc);
    font-family: var(--font);
    font-size: var(--fs);
    font-style: normal;
    font-weight: 500;
    line-height: 155.556%;
    /* 24.889px */
}

.inputs-error {
    border-color: red;
    color: red;
}

.lastinput {
    margin-bottom: 0;
}

/* error page */
.errorpage {
    height: 70vh;
}


/* these css should be last */
/* for page load animations */
.pos-left {
    left: -100vw;
}

.pos-right {
    right: -100vw;
}

.pos-bottom {
    bottom: -100vh;
}

.head-back{
    display: flex;
    align-items: center;
}


/* mobile responsive */
@media (max-width: 767px) {

    /* home */
    .headset {
        position: initial;
        top: 0;
        left: 0;
        position: relative;
        max-width: 100%;
    }

    .home-banner {
        margin: 3vw auto;
    }

    .mini-head {
        font-size: 2.717vw;
    }

    .main-head {
        margin: 2.717vw 0;
    }

    .head-para {
        max-width: 80%;
        font-size: var(--fs);
    }

    .action-btn {
        padding: 3.3vw 5.342vw;
    }

    .action-btn p {
        font-size: 3.5vw;
    }

    .counter-num {
        font-size: 5vw;
    }

    .counter-box {
        margin-top: 6vw;
    }

    .mob-headset {
        position: relative;
        transition: 0.5s;
        left: -100vw
    }

    .mob-counter {
        position: relative;
        transition: 0.5s;
        right: -100vw;
    }

    .mob-banner {
        position: relative;
        transition: 0.5s;
        right: -100vw;
    }

    .mob-footer {
        position: absolute;
        transition: 0.5s;
        bottom: 0;
        height: 100%;
        width: 100%;
    }

    /* home ends */

    /* project */
    .mob-box {
        margin-top: 5vw;
    }

    .project-card {
        min-width: 100%;
        min-height: 100%;
        margin-right: 1.31vw;
    }

    .page-head {
        font-size: var(--fs-head);
    }

    .project-card-name {
        font-size: var(--fs-head);
    }

    .project-card-year {
        font-size: 3.7vw;
    }

    .project-link {
        font-size: 3.7vw;
    }

    .project-card-arrow {
        width: 6vw;
        height: 6vw;
        margin-left: 1vw;
    }

    .project-card {
        margin: 3vw auto 9vw;
    }

    .mob-project-left {
        position: relative;
        left: -100vw;
        transition: 0.5s;
    }

    .mob-project-right {
        position: relative;
        right: -100vw;
        transition: 0.5s;
    }

    /* project ends */

    /* about */
    .amit {
        width: 100%;
    }

    .about-left {
        width: 100%;
        margin-top: 5vw;
    }

    .about-yhead {
        font-size: 5vw;
    }

    /* about ends */

    /* contact */
    .sub-head {
        max-width: 100%;
    }

    .connection-links {
        margin-top: 5vw;
    }

    .connect-link {
        min-width: 39vw;
        margin-right: 6.15vw;
        margin-bottom: 4vw;
    }

    .inputs {
        margin-bottom: 2vw;
        border-radius: 0.3vw;
        padding: 2vw;
        /* 24.889px */
    }

    .mob-map {
        margin-top: 8vw;
        height: 50vh;
    }
   .projects-section{
    display: flex !important; 
   }
    /* contact ends */
}